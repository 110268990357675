import React from "react"
import ContactFormWrapper from "./contactForm.style"
import Input from "reusecore/src/elements/Input";
import Button from "reusecore/src/elements/Button";

class ContactForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSending: false,
            successfullySubmitted: false,
            xhrErrorOnSubmit: null,
            name: "",
            company: "",
            phone: "",
            email: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitContactForm = this.submitContactForm.bind(this);
    }

    /**
     * Change input state
     *
     * @param name
     * @param value
     */
    handleInputChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    submitContactForm() {

        this.setState({
            isSending: !this.state.isSending
        });

        const { email, name, company, phone } = this.state;
        const data = {
            name,
            company,
            email,
            phone
        };

        console.log(data);

        try {

            fetch('https://us-central1-skick-f2ac3.cloudfunctions.net/callMeBack', {
                method: "POST",
                mode: 'cors',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {

                if(!response.ok) {

                }

                this.setState({
                    isSending: false,
                    successfullySubmitted: true
                })
            });

        } catch (error) {

        }
    }

    render() {

        const { name, company, phone, email, isSending, successfullySubmitted} = this.state;

        return (
            <ContactFormWrapper>
                { !successfullySubmitted &&
                <>
                    <div>
                        <Input
                            inputType="text"
                            placeholder="Uw naam"
                            iconPosition="right"
                            isMaterial={false}
                            className="email_input"
                            value={name}
                            onChange={(value) => this.handleInputChange("name", value)}
                        />
                    </div>

                    <div>
                        <Input
                            inputType="text"
                            placeholder="Bedrijfsnaam"
                            iconPosition="right"
                            isMaterial={false}
                            className="email_input"
                            value={company}
                            onChange={(value) => this.handleInputChange("company", value)}
                        />
                    </div>

                    <div>
                        <Input
                            inputType="text"
                            placeholder="Uw telefoonnummer"
                            iconPosition="right"
                            isMaterial={false}
                            className="email_input"
                            value={phone}
                            onChange={(value) => this.handleInputChange("phone", value)}
                        />
                    </div>

                    <div>
                        <Input
                            inputType="email"
                            placeholder="Email"
                            iconPosition="right"
                            isMaterial={false}
                            className="email_input"
                            value={email}
                            onChange={(value) => this.handleInputChange("email", value)}
                        />
                    </div>

                    <Button
                        title="Demonstratie aanvragen"
                        onClick={this.submitContactForm}
                        isLoading={isSending}
                        iconPosition="left"
                    />
                </>
                }

                { successfullySubmitted &&
                <div className="contact-success-message">
                    Wij zullen morgen telefonisch contact opnemen!
                </div>
                }
            </ContactFormWrapper>
        );
    }
}

ContactForm.defaultProps = {
    button: {
        type: 'button',
        fontSize: `${2}`,
        fontWeight: '600',
        borderRadius: '4px',
        pl: '22px',
        pr: '22px',
        colors: 'primaryWithBg',
        height: `${4}`,
    }
};

export default ContactForm