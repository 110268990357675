import React from "react"
import Input from "reusecore/src/elements/Input";
import Button from "reusecore/src/elements/Button";
import ContactFromWrapper from "./callMeBack.style";

class CallMeBack extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSending: false,
            successfullySubmitted: false,
            xhrErrorOnSubmit: null,
            value: ""
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleClick() {
        this.setState({
            isSending: !this.state.isSending
        });


        try {

            const data = {
                phone: this.state.value
            };

            fetch('https://us-central1-skick-f2ac3.cloudfunctions.net/callMeBack', {
                method: "POST",
                mode: 'cors',
                body:JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {

                if(!response.ok) {

                }

                this.setState({
                    isSending: false,
                    successfullySubmitted: true
                })
            });
        } catch (error) {
            // set error
        }
    }

    handleInputChange(value) {
        this.setState({
            value
        })
    }

    render() {

        const { value, isSending, successfullySubmitted } = this.state;

        return (
            <ContactFromWrapper>
                { !successfullySubmitted &&
                <>
                    <Input
                        inputType="text"
                        placeholder="Uw telefoonnummer"
                        iconPosition="right"
                        isMaterial={false}
                        className="email_input"
                        value={value}
                        onChange={this.handleInputChange}
                    />
                    <Button
                        title="Bel mij terug"
                        onClick={this.handleClick}
                        isLoading={isSending}
                        iconPosition="left"
                    />
                </>
                }

                { successfullySubmitted &&
                <div className="contact-success-message">
                    Wij zullen morgen telefonisch contact opnemen!
                </div>
                }

            </ContactFromWrapper>
        )
    }
}

export default CallMeBack