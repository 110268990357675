import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Heading from 'reusecore/src/elements/Heading';
import Text from 'reusecore/src/elements/Text';
import Container from '../../../components/UI/Container';
import FeatureBlock from '../../../components/FeatureBlock';
import { WorkSmarterWrapper } from "./workSmarter.style";
import Box from "reusecore/src/elements/Box";
import ContactForm from "../Contact/ContactForm";
import Image from "reusecore/src/elements/Image";
import ContactImage from "../../../assets/image/crypto/contact.png";


const WorkSmarterSection = ({title, description, row, col}) => {

    return (
        <WorkSmarterWrapper id="contact">
            <Container noGutter mobileGutter>

                <FeatureBlock
                    title={<Heading {...title} />}
                    description={<Text {...description} />}
                />

                <Box {...row}>
                    <Box {...col}>
                        <Image src={ContactImage} alt="Contact" className="contactImage" />
                    </Box>

                    <Box {...col}>
                        <ContactForm />
                    </Box>
                </Box>

            </Container>
        </WorkSmarterWrapper>
    )
};

WorkSmarterSection.propTypes = {
    title: PropTypes.object,
    description: PropTypes.object,
};

WorkSmarterSection.defaultProps = {
    title: {
        content: 'Is uw bedrijf klaar voor de toekomst?',
        fontSize: ['24px', '26px', '30px', '36px', '40px'],
        lineHeight: ['30px', '32px', '40px', '50px', '55px'],
        fontWeight: '700',
        color: '#32325d',
        letterSpacing: '-0.010em',
        mb: '20px',
        textAlign: ['center', 'center'],
    },

    description: {
        content:
            'Met de cloud-software voor het MKB van skick in ieder geval wel! Vraag vrijblijvend een adviesgesprek aan, om elkaar beter te leren kennen. Vul daarvoor hieronder het contactformulier in.',
        fontSize: '16px',
        fontWeight: '400',
        color: '#525f7f',
        lineHeight: '28px',
        mb: ['25px', '25px', '30px', '70px', '70px'],
        textAlign: ['center', 'center'],
    },

    row: {
        flexBox: true,
        justifyContent: 'center',
    },
    col: {
        pr: '15px',
        pl: '15px',
        width: [1, 1 / 2],
        flexBox: true,
        alignSelf: 'center',
    },
};

export default WorkSmarterSection;