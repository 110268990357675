import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from 'common/src/containers/Crypto/Navbar';
import Banner from 'common/src/containers/Crypto/BannerSection';
import BannerSlider from 'common/src/containers/Crypto/BannerSlider';
import Transactions from 'common/src/containers/Crypto/Transaction';
import TrustedProofSections from 'common/src/containers/Crypto/TrustedProof';
import Footer from 'common/src/containers/Crypto/Footer';
import ToolsSection from 'common/src/containers/Crypto/ToolsSection';
import WorkSmarterSection from 'common/src/containers/Crypto/WorkSmarterSection';
import { Helmet } from "react-helmet"

export default () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Fragment>
        <Helmet>
          <title>Skick | Jouw digitale sidekick!</title>
          <meta name="Description" content="React next landing page" />
          <meta name="theme-color" content="#ec5555" />

          {/* Load google fonts */}
          <link
            href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Heebo:300,400,500,700"
            rel="stylesheet"
          />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <BannerSlider />
          <TrustedProofSections />
          {/*<ControlSections />*/}
          <WorkSmarterSection/>
          <Transactions />
          <ToolsSection />
          {/*<ScalableSections />
          <SlideSections />

          <BetaSections />*/}
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
