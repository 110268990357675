import React from 'react'
import Container from '../../../components/UI/Container';
import { ToolsWrapper, FeatureSection } from './toolsSection.style'
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import FeatureBlock from '../../../components/FeatureBlock';
import { TOOLS } from '../../../data/Crypto';


const ToolsSection = ({
    row,
    title,
    description,
    featureTitleStyle,
    featureDescriptionStyle
}) => {
    return (
        <ToolsWrapper id="tools">
            <Container noGutter mobileGutter>
                <Box className="row" {...row}>
                    <FeatureBlock
                        title={<Heading {...title} />}
                        description={<Text {...description} />}
                    />
                </Box>

                <Box className="row" {...row}>
                    <FeatureSection>
                        {TOOLS.map((item, index) => (
                            <div key={`feature-${index}`} className="featureWrapper">
                                <Image src={item.image} alt={item.title} />
                                <Box className="contextPortion">
                                    <Heading
                                        as="h3"
                                        content={item.title}
                                        {...featureTitleStyle}
                                    />

                                    <Text content={item.des} {...featureDescriptionStyle} />
                                </Box>
                            </div>
                        ))}
                    </FeatureSection>
                </Box>
            </Container>
        </ToolsWrapper>
    );
};

ToolsSection.defaultProps = {
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        ml: '-15px',
        mr: '-15px',
        flexDirection: 'column',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    title: {
        content: 'Het slimste pakket op de markt',
        fontSize: ['24px', '26px', '30px', '36px', '40px'],
        lineHeight: ['30px', '32px', '40px', '50px', '55px'],
        fontWeight: '700',
        color: '#32325d',
        letterSpacing: '-0.010em',
        mb: '20px',
        textAlign: ['center', 'center'],
    },

    description: {
        content: 'Ontdek hier enkele van onze unieke functies',
        fontSize: '16px',
        fontWeight: '400',
        color: '#525f7f',
        lineHeight: '28px',
        mb: ['25px', '25px', '30px', '30px', '45px'],
        textAlign: ['center', 'center'],
    },
    featureTitleStyle: {
        fontSize: ['18px', '18px', '20px', '20px', '20px'],
        lineHeight: ['1', '1', '1', '1', '1'],
        fontWeight: '500',
        color: '#32325d',
        letterSpacing: '-0.010em',
        mb: '10px',
        textAlign: ['left', 'left'],
    },
    // Trusted section description default style
    featureDescriptionStyle: {
        fontSize: '16px',
        fontWeight: '400',
        color: '#525f7f',
        lineHeight: '27px',
        textAlign: ['left', 'left'],
    },
};

export default ToolsSection