import styled from 'styled-components';

const WorkSmarterWrapper = styled.section`
    padding: 180px 0 150px;
    background-color: rgb(243,242,250);
    
    @media (max-width: 1440px) {
        padding: 150px 0 130px;
    }
    
    @media (max-width: 480px) {
        padding: 110px 0 80px;
    }
    
    .contactImage {
        border-radius: 10px;
        height: 500px;
    }
`;

export { WorkSmarterWrapper };