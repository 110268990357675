import AuthorOne from '../../assets/image/crypto/author-4.jpg';
import AuthorTwo from '../../assets/image/crypto/author-2.jpg';
import AuthorThree from '../../assets/image/crypto/author-3.jpg';
import AuthorFour from '../../assets/image/crypto/author-1.jpg';
import FEATURE1 from '../../assets/image/crypto/tf1.svg';
import FEATURE2 from '../../assets/image/crypto/tf2.svg';
import FEATURE3 from '../../assets/image/crypto/tf3.svg';
import FEATURE4 from '../../assets/image/crypto/tf4.svg';
import PROOF1 from '../../assets/image/crypto/proof1.svg';
import PROOF2 from '../../assets/image/crypto/proof2.svg';
import PROOF3 from '../../assets/image/crypto/proof3.svg';
import PROOF4 from '../../assets/image/crypto/proof4.svg';
import PROOF5 from '../../assets/image/crypto/proof5.svg';
import PROOF6 from '../../assets/image/crypto/proof6.svg';
import JACKPOTIMG from '../../assets/image/crypto/jackpot.svg';
import BETA1 from '../../assets/image/crypto/beta-1.svg';
import BETA2 from '../../assets/image/crypto/beta-2.svg';
import BETA3 from '../../assets/image/crypto/beta-3.svg';

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Oplossingen',
    path: '#trusted',
    offset: '0',
  },
  {
    label: 'Onderdelen',
    path: '#tools',
    offset: '-10',
  },

  /*{
    label: 'Prijzen',
    path: '#featureslider',
    offset: '-10',
  },*/
  {
    label: 'Ons team',
    path: '#footerSection',
    offset: '380',
  },
];

export const TESTIMONIALS = [
  {
    review:
      'Met Skick houden we overzicht in het bedrijf.',
    name: 'Anton Schoenmaker',
    designation: 'Tien Schilder-Glas-Afbouw',
    avatar: `${AuthorOne}`,
  },
  {
    review:
      'Ik verstuur gemakkelijk offertes en facturen.',
    name: 'Etienne Ubaghs',
    designation: 'Ubahgs Schilderwerken',
    avatar: `${AuthorTwo}`,
  },
  {
    review:
      'We worden deskundig geholpen bij de boekhouding',
    name: 'Jan Klaashof',
    designation: 'Klaashof Schilders',
    avatar: `${AuthorThree}`,
  },
  {
    review:
      'Het grote voordeel is dat we professioneel met de klant communiceren.',
    name: 'Hans Achterbeek',
    designation: 'Installatiebureau Achterbeek',
    avatar: `${AuthorFour}`,
  },
];
export const TRANSACTIONS_FEATURE = [
  {
    image: FEATURE1,
    title: 'Orde in de chaos',
    des: 'Documenteer uw projecten digitaal, zodat u geen rondslingerend papierwerk meer heeft.',
  },
  {
    image: FEATURE2,
    title: 'Professioneel communiceren',
    des: 'Met standaard berichten, offerte herinneringen en intake formulieren communiceert u professioneel.',
  },
  {
    image: FEATURE3,
    title: 'Klaar voor de toekomst',
    des: 'Start met werken in de cloud, zodat u voorbereid bent op groei in de toekomst.',
  },
  /*{
    image: FEATURE4,
    title: '',
    des:
      'Callbacks are sent to the URL you specified. You can process customer order',
  },*/
];
export const PROOFS_FEATURE = [
  {
    image: PROOF1,
    title: 'Altijd zien wie belt',
    des: 'Elk telefoonnummer wordt automatisch opgeslagen in bedrijfstelefoons.',
  },
  {
    image: PROOF2,
    title: 'Scoor meer opdrachten',
    des: 'Met automatische offerte herinneringen en professionele communicatie.',
  },
  {
    image: PROOF3,
    title: 'Snelle betalingen',
    des: 'Met twee klikken verstuur je een factuur en laat deze betalen met iDeal.',
  },
  {
    image: PROOF4,
    title: 'Werkbonnen volgen',
    des: 'Elke actie wordt vastgelegd, zo is uw project goed gedocumenteerd.',
  },
  {
    image: PROOF5,
    title: 'Feedback formulieren',
    des: 'Gebruiksvriendelijke feedbackformulieren bij intake of offerte.',
  },
  {
    image: PROOF6,
    title: 'Live kostenoverzicht',
    des: 'Voor de klant bij regie- of extrawerk, bereikbaar via telefoon.',
  },
];

export const TOOLS = [
    {
        image: PROOF1,
        title: 'Relatiebeheer',
        des: 'Sla al je zakelijke contacten op',
    },
    {
        image: PROOF2,
        title: 'Digitale werkbon',
        des: 'Vind alles terug in de werkbon',
    },
    {
        image: PROOF3,
        title: 'Online offertes',
        des: 'Offertes op je telefoon',
    },
    {
        image: PROOF4,
        title: 'Zakelijke e-mail',
        des: 'Met Google Suite',
    },
    {
        image: PROOF5,
        title: 'Urenregistratie',
        des: 'Online werkbriefje',
    },
    {
        image: PROOF6,
        title: 'Facturen maken',
        des: 'En verzenden per e-mail',
    },
    {
        image: PROOF1,
        title: 'Documenten en foto\'s',
        des: 'Cloudopslag zonder limiet',
    },
    {
        image: PROOF2,
        title: 'Planningen',
        des: 'Voor een duidelijk overzicht',
    },
    {
        image: PROOF3,
        title: 'Inkoopfacturen',
        des: 'Makkelijk terugvinden',
    },
    {
        image: PROOF4,
        title: 'Boekhouding',
        des: 'Weet hoe je bedrijf ervoor staat',
    },
    {
        image: PROOF5,
        title: 'Nacalculatie',
        des: 'Hoe lopen je projecten?',
    },
    {
        image: PROOF6,
        title: 'Afspraakbevestiging',
        des: 'Voor professionele communicatie',
    },
];

export const SCALABLE_FEATURE = [
  {
    image: JACKPOTIMG,
    title: 'Daily Jackpot',
    des: '35000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Weekly Jackpot',
    des: '250000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Monthly Jackpot',
    des: '4999697 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Yearly Jackpot',
    des: '300245785000 CLV',
  },
];

export const BETA_FEATURE = [
    {
        image: BETA1,
        title: 'Altijd zien wie belt',
        des: 'Nieuwe klanten worden automatisch in uw telefoon geplaatst, waardoor u altijd ziet wie er belt.',
    },
    {
        image: BETA2,
        title: 'Scoor meer opdrachten',
        des: 'U wordt automatisch herinnerd wanneer een offerte nog niet beantwoord is. Een herinnering is met één druk op de knop verstuurd.',
    },
    {
        image: BETA3,
        title: 'Snelle betaling',
        des: 'Zet gewerkte uren of een offerte om in een factuur, en laat deze betalen met iDeal.',
    }
];

export const menuWidget = [
  {
    id: 1,
    title: 'Onderdelen',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'Relatiebeheer',
      },
      {
        id: 2,
        url: '#',
        text: 'Digtale werkbon',
      },
      {
        id: 3,
        url: '#',
        text: 'Online calculeren',
      },
      {
        id: 4,
        url: '#',
        text: 'E-mail',
      },
      {
        id: 5,
        url: '#',
        text: "Documenten & Foto's",
      },
    {
        id: 6,
        url: '#',
        text: 'Planningen maken',
    },
    {
        id: 7,
        url: '#',
        text: "Facturen met iDeal",
    },
    ],
  },
  {
    id: 2,
    title: 'Voordelen',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'Overal toegang tot uw bedrijf',
      },
      {
        id: 2,
        url: '#',
        text: 'Geen rondslingerend papier',
      },
      {
        id: 3,
        url: '#',
        text: 'Veilige opslag in EU',
      },
      {
        id: 4,
        url: '#',
        text: 'Altijd bereikbaar',
      },
      {
        id: 5,
        url: '#',
        text: 'Professionele communicatie',
      },
        {
            id: 6,
            url: '#',
            text: 'Klaar voor de toekomst',
        },
    ],
  },
  {
    id: 3,
    title: 'Contact',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'info@skick.app',
      },
      {
        id: 2,
        url: '#',
        text: 'Rumpsterweg 18b, Bunnik',
      },
      {
        id: 3,
        url: '#',
        text: '030-2270469',
      },
      {
        id: 4,
        url: '#',
        text: 'KvK 67207898',
      },
        {
            id: 5,
            url: '#',
            text: 'Onderdeel van Vakcloud B.V.',
        }
    ],
  },
];
export const Language_NAMES = [
  {
    label: 'English',
    value: 'eng',
  },
  {
    label: 'Chinese',
    value: 'chinese',
  },
  {
    label: 'Indian',
    value: 'indian',
  },
];
